//Real token information

import { BridgeAddressProps } from "../types/types";

export const realTokenList = [
  // {
  //   chainId: 421614,
  //   tokenAddress: "0xB01496ed0171dc66aB743B4604671D57c00C6182",
  //   chainName: "arb sepolia",
  //   tokenName: "DEUS",
  //   decimals: 18,
  // },
  // {
  //   chainId: 11155111,
  //   tokenAddress: "0xbB46230a165366A02ED5793E46b83866831e7d5D",
  //   chainName: "sepolia",
  //   tokenName: "DEUS",
  //   decimals: 18,
  // },
  {
    chainId: 250,
    tokenAddress: "0xde55b113a27cc0c5893caa6ee1c020b6b46650c0",
    chainName: "fantom",
    tokenName: "DEUS",
    decimals: 18,
  },
  {
    chainId: 43114,
    tokenAddress: "0xde5ed76e7c05ec5e4572cfc88d1acea165109e44",
    chainName: "avalanche",
    tokenName: "DEUS",
    decimals: 18,
  },
];

// MB tokens information

export const MBTokenList = [
  // {
  //   chainId: 421614,
  //   tokenAddress: "0xe8E36D3FbB933f4aa45ED0B1c3D8Ae942535a529",
  //   chainName: "arb sepolia",
  //   tokenName: "DEUS MBToken",
  //   decimals: 18,
  //   gateWayAddress: "0xF1BBDB5e3F46f6dCcbC03Ed1DAa23F188eD74318",
  // },
  // {
  //   chainId: 11155111,
  //   tokenAddress: "0xF2A55D2862523d7e4b2ebCB42F6E3ff2afED85D0",
  //   chainName: "sepolia",
  //   tokenName: "DEUS MBToken",
  //   decimals: 18,
  //   gateWayAddress: "0x4e7f844959b8488fF6d6E939CB644D1D98cdd0B2",
  // },
  {
    chainId: 250,
    tokenAddress: "0x650a795b3b728Df29c252EF49CFD5884b68D8644",
    chainName: "fantom",
    tokenName: "mbDEUS",
    decimals: 18,
    gateWayAddress: "0xCcd1f65346f6CEc7E331184ab16869F145E8383e",
  },
  {
    chainId: 43114,
    tokenAddress: "0x650a795b3b728Df29c252EF49CFD5884b68D8644",
    chainName: "avalanche",
    tokenName: "mbDEUS",
    decimals: 18,
    gateWayAddress: "0xCcd1f65346f6CEc7E331184ab16869F145E8383e",
  },
];

//Bridge contract addresses
export const BridgeAddresses: BridgeAddressProps = {
  // 11155111: "0xdF9013A32fe6474aF668681ECA9cAF54dF8B8DDF",
  // 421614: "0x2aB14976B7feffD5859201c71011E5D31166AC9d",
  250: "0x028Fc3EAD4D2bA8060538A5CF8E2AFEf481495Ba",
  43114: "0x028Fc3EAD4D2bA8060538A5CF8E2AFEf481495Ba",
};

export const TxExplorerUrl: BridgeAddressProps = {
  // 11155111: "https://sepolia.etherscan.io/tx/",
  // 421614: "https://sepolia.arbiscan.io/tx/",
  250: "https://ftmscan.com/tx/",
  43114: "https://snowtrace.io/tx/",
};
