"use client";
import { config } from "../config";
import { Address, erc20Abi } from "viem";
import { realTokenList } from "../../constants/contractAddresses";
import { useReadContracts } from "wagmi";
import useWalletAccount from "./useWalletAccount";
import { useEffect, useState } from "react";
import { userBalanceProp } from "../../types/types";

export const useFetchRealTokenBalances = () => {
  const [userTokenBalances, setBalances] = useState<userBalanceProp[] | null>(
    null
  );
  const tokenContracts = [];
  const { account } = useWalletAccount();

  for (let i = 0; i < realTokenList.length; i++) {
    const token = realTokenList[i];
    tokenContracts.push({
      abi: erc20Abi,
      address: token.tokenAddress as Address,
      functionName: "balanceOf",
      args: [account],
      chainId: token.chainId,
    });
  }

  const { data: balanceData, refetch: refetchTokenBalance } = useReadContracts({
    config,
    contracts: tokenContracts,
  });

  useEffect(() => {
    if (account) {
      refetchTokenBalance();
    }
  }, [account, refetchTokenBalance]);

  useEffect(() => {
    if (!balanceData) return;
    const updateBalance = realTokenList.map((token, index) => ({
      ...token,
      userTokenBalance: !balanceData[index].error
        ? BigInt(balanceData[index]!.result!.toString())
        : BigInt("0"),
    }));

    setBalances(updateBalance);
  }, [balanceData]);

  return { userTokenBalances, refetchTokenBalance };
};
