"use client";
import { useAccount } from "wagmi";
import { config } from "../config";

const useWalletAccount = () => {
  const result = useAccount({ config });
  const isWrongNetWork = !result.chain;
  return {
    account: result.address,
    connectedChain: result.chain,
    connectedChainId: result.chainId,
    isConnected: result.isConnected,
    isDisconnected: result.isDisconnected,
    status: result.status,
    connector: result.connector,
    isWrongNetWork: isWrongNetWork,
  };
};

export default useWalletAccount;
