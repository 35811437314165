"use client";
import { Address, erc20Abi } from "viem";
import useWalletAccount from "./useWalletAccount";
import { readContract } from "@wagmi/core";
import { config } from "../config";
import { BridgeAddresses } from "../../constants/contractAddresses";
import { TokenProp } from "../../types/types";

import { fromWei } from "../../utils/web3BigNumbers";

const useUserAllowance = () => {
  const { account } = useWalletAccount();

  const handleFetchAllowance = async (
    token: TokenProp | null,
    setUserAllowance: (allowance: string) => void
  ) => {
    if (!token) return;
    try {
      const result = await readContract(config, {
        abi: erc20Abi,
        address: token!.tokenAddress as Address,
        functionName: "allowance",
        args: [account!, BridgeAddresses[token!.chainId] as Address],
        chainId: token!.chainId,
      });
      setUserAllowance(fromWei(result.toString()));
    } catch {
      setUserAllowance("0");
    }
  };

  return { handleFetchAllowance };
};

export default useUserAllowance;
