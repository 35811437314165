import { useRef } from "react";
import { useOutsideClick } from "../../hooks/useOutSideClick";

type props = {
  title?: string;
  titleLeft?: string;
  isOpen: boolean;
  children: React.ReactNode;
  size?: "small" | "medium" | "large";
  closeModalHandler: () => void;
  modalClassName?: string;
};

const Modal = ({
  title,
  titleLeft,
  children,
  isOpen,
  closeModalHandler,
  size,
  modalClassName,
}: props) => {
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    if (isOpen) closeModalHandler();
  });

  return (
    <>
      {isOpen && (
        <div className="modalWrapper">
          <div
            className={`modalContent bg-gray-800 rounded-lg p-5 ${
              modalClassName && "p-0"
            }  ${
              size === "small"
                ? "w-[min(420px,90%)]"
                : size === "large"
                ? "w-[min(800px,80%)]"
                : "w-[min(500px,80%)]"
            }`}
            ref={ref}
          >
            <div
              className={`flex items-center mb-5 ${
                modalClassName && "py-5 px-4 mb-0 border-b"
              }`}
            >
              {titleLeft && (
                <p className="relative z-10 text-left text-xl text-white">
                  {" "}
                  {titleLeft}{" "}
                </p>
              )}
              {title && (
                <p className="modal-title relative z-10 ml-auto text-center text-sm font-bold text-white">
                  {" "}
                  {title}{" "}
                </p>
              )}
              <span
                onClick={closeModalHandler}
                className="close p-2 ml-auto cursor-pointer hover:bg-[#ffffff26] hover:rounded-full -mr-2"
                data-testid="close-modal"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z"
                    fill="#67677B"
                  />
                </svg>
              </span>
            </div>
            <div
              className={`modalChildrenWrapper bg-gray-800 z-10 p-5 ${
                modalClassName && "bg-gray-600"
              }`}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
