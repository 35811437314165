import { fantom, avalanche, sepolia, arbitrumSepolia } from "wagmi/chains";
import { Chain } from "wagmi/chains";
import { ChainEId, ChainLogos } from "../types/types";

export const ChainsLogo: ChainLogos = {
  // 421614: "https://cryptologos.cc/logos/arbitrum-arb-logo.png?v=033",
  // 11155111: "https://cryptologos.cc/logos/thumbs/ethereum.png?v=032",
  250: "https://cryptologos.cc/logos/fantom-ftm-logo.png?v=035",
  43114: "https://cryptologos.cc/logos/avalanche-avax-logo.png?v=035",
};

export const supportedChains: Chain[] = [
  // arbitrumSepolia,
  // {
  //   ...sepolia,
  //   rpcUrls: {
  //     ...sepolia.rpcUrls,
  //     default: { http: ["https://rpc.ankr.com/eth_sepolia"] },
  //   },
  // },
  fantom,
  avalanche
];

export const ChainEIds: ChainEId = {
  // 11155111: 40161,
  // 421614: 40231,
  250: 30112,
  43114: 30106,
};

export const EIdsChain: ChainEId = {
  // 40161: 11155111,
  // 40231: 421614,
  30112: 250,
  30106: 43114,
};

export enum SupportedChainId {
  // sepolia = 11155111,
  // arbitrumSepolia = 421614,
  fantom = 250,
  avalanche = 43114
}

export const SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(
  SupportedChainId
).filter((id) => typeof id === "number") as SupportedChainId[];
